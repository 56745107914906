import React from 'react';
import { State, Position, PositionToString } from '../App';
import { parse } from '../Expression';

type CellViewProps = {
  state: State
  position: Position
  onCellClick: (e : React.MouseEvent<HTMLElement>) => void
  onCellChange: (e: React.ChangeEvent<HTMLInputElement>) => void
};

const CellView = ({
  position,
  state,
  onCellClick
}: CellViewProps) => <td id={PositionToString(position)} className="text-left" onClick={onCellClick}>
  {parse(state.cells[position.x][position.y], position)}
</td>;

export default CellView;

/*
  <div className="text-left text-truncate">
    {parse(state.cells[position.x][position.y])}
  </div>

*/