import { parser } from 'mathjs';
import { PositionToExcel, Position } from './App';

const expressionPattern = /^\s*=(.*)$/;
const context = parser();

export const parse = (text: string, position?: Position): string => {
  let value = text;
  let result = text;
  const captured = text.match(expressionPattern);
  if (captured && captured.length === 2) {
    // console.log('text:', text, ', captured:', captured);
    value = captured[1];
    try {
      result = context.evaluate(value);
    } catch (e) {
      console.log('ERROR:', JSON.stringify(e));
      result = "#ERR";
    }
  }
  if (position) {
    // console.log(`position(${position.x},${position.y}) set ${PositionToExcel(position)} = ${result}`);
    if (result === "") {
      context.set(PositionToExcel(position).toLowerCase(), undefined);
    } else {
      context.set(PositionToExcel(position).toLowerCase(), result)
    }
  }
  return result;
}
