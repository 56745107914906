import React from 'react';
import { State, Position } from '../App';
import CellEditor from './CellEditor';
import CellView from './CellView';

type CellProps = {
  state: State
  position: Position
  onCellClick: (e : React.MouseEvent<HTMLElement>) => void
  onCellChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCellBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCellKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void
};

const Cell = ( props: CellProps ) => 
  props.state.active && props.state.active.x === props.position.x && props.state.active.y === props.position.y ?
    <CellEditor {...props} /> :
    <CellView {...props} />
;

export default Cell;