import React, { useState } from 'react';
import './App.css';
import './images/excel-header-background.gif';
import './images/excel-header-select-all.gif';
import Cell from './Components/Cell';

export type Position = {
  x: number
  y: number
};

export type State = {
  name: string
  cols: number[]
  rows: number[]
  active?: Position
  cells: string[][]
};

export const PositionToString = (position: Position) => JSON.stringify(position);
export const PositionFromString = (position: string) => JSON.parse(position);
export const PositionToExcel = (position: Position) => (position.x >= 26 ? String.fromCharCode(64 + (position.x / 26)) : "")+String.fromCharCode(65 + (position.x % 26))+(position.y+1);


const nbCols=26;
const nbRows=50;
const initialDocumentName = "Nouveau";
const initialState : State = {
  name: initialDocumentName,
  cols: Array.from({length: nbCols}, (_, i) => i),
  rows: Array.from({length: nbRows}, (_, i) => i),
  active: undefined,
  cells: Array.from({length: nbCols}, (_, x) => Array.from({length: nbRows}, (_, y) => {
    const key = PositionToExcel({x, y});
    const value = localStorage.getItem(key);
    return (value !== undefined && value !== null ) ?
      value :
      "";
  })),
};


const updateItemInArray = (array: any[], index: number, value: any) => array.map( (item, idx) => (idx !== index) ? item : value);
const updateCell = (cells: string[][], position: Position, value: string) => updateItemInArray(
  cells,
  position.x,
    updateItemInArray(
      cells[position.x], position.y, value
    )
);

function App() {
  const [ state, setState ] = useState(initialState)

  const save = () => {
    var fileContent = state.cells
      .map( (cols: string[], c: number) => cols
        .map( (row: string, r: number) => row)
        .join(",")
      )
      .join("\n");
    var blob = new Blob([fileContent ], { type: 'text/csv' });
    var a = document.createElement('a');
    a.download = `${state.name}.csv`;
    a.href = window.URL.createObjectURL(blob);
    a.click();
  };

  const onCellClick = (e : React.MouseEvent<HTMLElement>) => {
    const position = PositionFromString(e.currentTarget.id);
    setState({
      ...state,
      active: position
    });
  }
  const onCellChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const position = PositionFromString(e.currentTarget.id);
    const value = e.target.value !== undefined && e.target.value !== null ? e.target.value : "";
    const parse = (value: string) : string => {
      if (value==="=document.clear()") {
        localStorage.clear();
        window.location.reload(false);
        return "";
      } else if (value === "=document.save()") {
        save();
        return "";
      }
      return value;
    }
    const captured = value.match(/^\s*=document\.name\(\s*"([^"]+)"\s*\);?$/);
    const name = (captured && captured.length === 2) ? captured[1] : state.name;
    const cellValue = name === state.name ? parse(value) : "";
    setState({
      ...state,
      name,
      cells: updateCell(state.cells, position, cellValue)
    });
    if (cellValue === "") {
      localStorage.removeItem(PositionToExcel(position));
    } else {
      localStorage.setItem(PositionToExcel(position), cellValue);
    }
  }

  const onCellBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      active: undefined
    });
  }

  const onCellKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setState({
        ...state,
        active: undefined
      });
    }
  }


  return (
    <div className="App">
      <div className="responsive">
      <table className="excel">
        <thead>
          <tr key="00">
            <th key="00,00" className="heading">&nbsp;</th> 
            {
            state.cols.map( (c: number) =>
            <th key={`00,{${c}}`} scope="col">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {(c >= 26 ? String.fromCharCode(64 + (c / 26)) : "")+String.fromCharCode(65 + (c % 26))}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            state.rows.map( (r: number, index: number) => (
              <tr key={`{${r}}`}>
                <th scope="row" className="heading">{r +1}</th>
                { state.cols.map( (c: number) => 
                  <Cell key={`{${c},${r}}`}
                    position={{x: c, y: r}}
                    state={state}
                    onCellClick={onCellClick}
                    onCellChange={onCellChange}
                    onCellBlur={onCellBlur}
                    onCellKeyPress={onCellKeyPress}
                  />) }
              </tr>
            ))
          }
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default App;
